import React, { useState } from 'react';
import viewStates from '../helpers/viewStates';

const defaultState = {
  language: 'EN',
  view: viewStates.START,
  category: false,
  messages: [],
  canInteract: false,
};

export const ChatbotContext = React.createContext({});

export const ChatbotProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  return (
    <ChatbotContext.Provider value={[state, setState]}>
      {children}
    </ChatbotContext.Provider>
  );
};
