import React, { useState } from 'react';

const defaultState = {};

export const AdminContext = React.createContext({});

export const AdminProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  return (
    <AdminContext.Provider value={[state, setState]}>
      {children}
    </AdminContext.Provider>
  );
};
