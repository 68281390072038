import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { AdminProvider } from './contexts/admin-context';
import { ChatbotProvider } from './contexts/chatbot-context';
// import reportWebVitals from './reportWebVitals';
import './i18n';
import axios from 'axios';

// datepicker CSS first
import 'react-dates/lib/css/_datepicker.css';

// lazy load the Chatbot and Admin components so the proper SCSS get loaded
const ChatBot = React.lazy(() => import('./components/chatbot/ChatBot'));
const Admin = React.lazy(() => import('./components/admin/Admin'));

const { REACT_APP_API_URL } = process.env;

// we save the API URL as base url. Saves a lot of typing later on :)
axios.defaults.baseURL = `${REACT_APP_API_URL}/api`;

axios.defaults.validateStatus = (status => {
  return status >= 200 && status < 500; // override default
});

// Add a request interceptor to always add the language to each request
axios.interceptors.request.use(function (config) {
  const xLang = localStorage.getItem('x-language');
  if (xLang) {
    config.headers['X-Language'] = xLang;
  }

  const sessionId = localStorage.getItem('session_id');
  if (sessionId) {
    config.headers['X-Session-ID'] = sessionId;
  }

  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/admin">
          <AdminProvider>
            <Suspense fallback={<div>Loading</div>}>
              <Admin />
            </Suspense>
          </AdminProvider>
        </Route>
        <Route path="/:lang?">
          <ChatbotProvider>
            <Suspense fallback={<div>Loading</div>}>
              <ChatBot />
            </Suspense>
          </ChatbotProvider>
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
