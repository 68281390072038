import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationET from '../locales/et/translation.json';
import translationRU from '../locales/ru/translation.json';
import translationLV from '../locales/lv/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  et: {
    translation: translationET,
  },
  ru: {
    translation: translationRU,
  },
  lv: {
    translation: translationLV,
  }
};

i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
// .use(LanguageDetector)
// pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    resources,
  });

export default i18n;
